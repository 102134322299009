import { axiosApi } from '~/api/api.js'
import { Message,MessageBox } from 'element-ui'
import m from '~/main.js'
const basePath = "/customer";
const paymodule = {
	state: {
		step: 1,
		banklist:[],//输入银行卡判断是哪个银行
		errormsg:"",//验证码错误消息
		time: 59, //时间倒计时
		cleartime: -1, //计时器
		timeb: false, //控制倒计时秒数是否显示
		result:null,//请求结果
		unresult:null,//解绑请求结果
		disabled:true,//获取验证码按钮是否可点击
		//交易记录
		tradeType:[ //业务大类，业务小类
			{label:'充值提现',value:10,children:[{label:'在线充值',value:1101},{label:'在线消费充值',value:1102},{label:'提现失败返点',value:1106},{label:'余额提现',value:2101}]},
			{label:'转账',value:20,children:[]},
			{label:'交易',value:30,children:[{label:'订单结算',value:1301},{label:'订单撤销退点',value:1303},{label:'订单扣款',value:2301},{label:'订单回款撤销',value:2304}]},
			{label:'调帐',value:40,children:[]},
			{label:'利润',value:31,children:[]},
			{label:'退款',value:32,children:[]},
			{label:'手续费',value:50,children:[{label:'在线支付手续费加点',value:1501},{label:'在线支付手续费扣款',value:2501}]},
			{label:'促销',value:60,children:[]},
			{label:'其他',value:100,children:[]}
		],
		//查询表单
		formInline: {
			fundsId: null,  //流水编号
			time: [],		
			tradeTypeId: null,  //业务大类
			busiTypeId: null,  //业务小类
			fundsType: null, //流水类型 
			startCreateTime: null, //开始时间
			endCreateTime: null //结束时间
		},
		paymentdata:[], // zjl 交易记录数据
		total:0, //zjl 交易记录总条数
		currentPage:1, //zj l
		fundsType: '0', //明细类型 流水类型2-支出 1-收入
		pageSize: 10, //zjl 每页显示条目个数
		//交易记录
		paymentTab:'7',  //账户中心Tab切换
		isTabShow: true, // 所有交易记录 tab是否显示

		saleAnalyseList: [],
		expenseAnalyseList: [],
		receiptAnalyseList: [],
		amountInfo: {},
		showCreditList: [],
		showCreditTotal: 0,
		loading: false,
	},
	mutations: {
		clearmsg(state) {
			state.errormsg = ""
		},
		stepdeal(state){//设置支付密码下一步操作
			state.step++
			let payform = Object.assign(JSON.parse(sessionStorage.getItem("payform")), {
				step: state.step
			})
			sessionStorage.setItem("payform", JSON.stringify(payform))
		},
		activestep(state){//刷新页面获取当前step
			state.step = JSON.parse(sessionStorage.getItem("payform")).step
		},
		changedisabled(state,payload){//控制获取验证码按钮是否可点击
			state.disabled = payload
		},
		cleartime(state){
			state.timeb = false
			state.time = 59
		},
		//交易记录zjl
		search(state){  //查询
			state.currentPage = 1
			if (state.formInline.time) {

                if(state.formInline.time.length > 0) {
					state.formInline.time = state.formInline.time.map((item) => {
						if (!item) {
							return null
						}
						return new Date(item)
					})
                    console.log(state.formInline.time);
					state.formInline.startCreateTime = state.formInline.time[0]
					state.formInline.endCreateTime = state.formInline.time[1]
				} else {
					state.formInline.startCreateTime = state.formInline.endCreateTime = null
				}
			}else{
				state.formInline.startCreateTime = state.formInline.endCreateTime = null
			}
			if (state.fundsId == "") { //流水编号
				state.fundsId = null
			}
			if (state.formInline.tradeTypeId != ''&& state.formInline.tradeTypeId != null){  //如果选中业务大类搜索，业务类型为空
				state.formInline.fundsType  = null
			}else{
				paymodule.mutations.tabChange(state,state.fundsType)
			}
		},
		currentpageChange(state,payload=1){ //分页change
			state.currentPage = payload
		},
		tabChange(state,payload){ //切换tab
			state.currentPage = 1
			console.log(payload)
			state.fundsType = payload
			switch (state.fundsType){
				case '0':
					state.formInline.fundsType  = null
					break;
				case '1':
					state.formInline.fundsType = 1
					break
				case '2': 
					state.formInline.fundsType = 2
					break
				default:
					break;
			}
		},
		// paymentTabClick(state, payload) {
		// 	console.log(payload,'payload')
		// 	state.paymentTab = payload
		// 	// paymodule.mutations.tradeType(state)
		// },
		// tradeType(state){  //paymentTab 改变
		// 	switch (state.paymentTab){
		// 		case '0':    //在途金额
		// 			state.formInline.tradeTypeId = null //大类
		// 			//state.formInline.busiTypeId = 1006 //小类 
		// 			state.formInline.busiTypeId = null //小类 
		// 			state.formInline.balanceTypeList= [1006]
		// 			break;
		// 		// case '1':    //充值记录
		// 		// 	state.formInline.tradeTypeId = 10
		// 		// 	state.formInline.busiTypeId = 1101
		// 		// 	state.formInline.balanceTypeList= []
		// 		// 	break
		// 		// case '2':   //提现记录
		// 		// 	state.formInline.tradeTypeId = 10 //大类
		// 		// 	state.formInline.busiTypeId = 2101 //小类
		// 		// 	state.formInline.balanceTypeList= []
		// 		// 	break
		// 		// case '3':     //退款记录
		// 		// 	state.formInline.tradeTypeId = 32 //大类
		// 		// 	state.formInline.busiTypeId = null //小类
		// 		// 	state.formInline.balanceTypeList= []
		// 		// 	break
		// 		case '6':     //可提现金额
		// 			// state.formInline.tradeTypeId = 32 //大类
		// 			// state.formInline.busiTypeId = 1001//小类
		// 			state.formInline.busiTypeId = null //小类 
		// 			state.formInline.tradeTypeId = null //大类
		// 			state.formInline.balanceTypeList= [1001]
		// 			break

		// 		default:
		// 			break;
		// 	}
		// },
		clearForm(state){ //清空查询条件
			state.formInline =  {
				fundsId: null,  //流水编号
				time: [],		
				tradeTypeId: null,  //业务大类
				busiTypeId: null,  //业务小类
				fundsType: null, //流水类型 
				startCreateTime: null, //开始时间
				endCreateTime: null //结束时间
			}
		},
		goOrderDetail(state,payload){   //订单编号存入回话存储（我的订单）
				sessionStorage.setItem('orderSn',payload)	
		},
		initTimeRange(state){
			let initDate = new Date().setHours(23, 59, 59, 0)
			state.formInline.time = [new Date(initDate - 1000 * 60 * 60 * 24 * 90),initDate]
		},
		// 授信分页
		setPaging(state, payload) { 
			let { type, page, pageSize } = payload
			if (type == '0') {
				state.showCreditList = paymodule.mutations.getListInfo(state, { page,pageSize, list: state.saleAnalyseList })
				state.showCreditTotal = state.saleAnalyseList.length
			} else if (type == '1') {
				state.showCreditList = paymodule.mutations.getListInfo(state, { page,pageSize, list: state.expenseAnalyseList })
				state.showCreditTotal = state.expenseAnalyseList.length
			} else { 
				state.showCreditList = paymodule.mutations.getListInfo(state, { page,pageSize, list: state.receiptAnalyseList })
				state.showCreditTotal = state.receiptAnalyseList.length
			}
		},
		getListInfo(state, payload) { 
			let { page, list, pageSize } = payload
			return list.slice((page-1)*pageSize,page*pageSize)
		}
	},
	actions: {
		//获取手机验证码
		vcode({state},payload) {
			axiosApi("/anon/cbs/pay/vcode", {type:payload.type,mobile:payload.mobile||null}, res => {
				
			})
		},
		//倒计时
		sendnum({state,dispatch},payload) {
				state.disabled = true
				state.timeb = true
				clearTimeout(state.cleartime)
				state.time = 59
				state.cleartime = setInterval(() => {
					state.time--
					if(state.time == 0) {
						state.disabled = false
						state.timeb = false
					}
				}, 1000)
				dispatch('vcode',payload)
		},
		//验证手机验证码
		vcodeValid({state,commit},payload){
			axiosApi("/anon/cbs/pay/vcodeValid",{type:2,vcode:payload},res=>{
				if(res.data.data)commit('stepdeal')
			},null,null,res=>{
				if(res.data.code.indexOf("SMS")>-1){
					state.errormsg = res.data.code=="SMS0006"?"输入错误次数过多，请稍后再试":res.data.message
				}
			})
		},
		//查询支持银行
		supportBanks({state},payload){
			axiosApi("/anon/cbs/pay/supportBanks",{bankCode:""},res=>{
				state.banklist = res.data.data
			})
		},
		//银行卡个人实名认证/绑定银行卡
		bindCard({state},payload){
			state.result = null//重置此字段，可监听到
			axiosApi("/anon/cbs/pay/bindCard",{
				realName:payload.realName,
				idCard:payload.idCard,
				bankCardNo:payload.bankCardNo,
				bankCardMobile:payload.bankCardMobile,
				bankCode:payload.bankCode,
				smsCode:payload.smsCode
			},res=>{
				if(payload.err==0){
					m.$router.push(`${basePath}/pay/verifyresult/0/${res.data.data?1:0}`);
				}else{
					state.result = res.data.data
				}
			  
			},null,null,res=>{
                if(res.data.code.indexOf("SMS")>-1){
					state.errormsg = res.data.code=="SMS0006"?"输入错误次数过多，请稍后再试":res.data.message
				}else{
					if(payload.err==1){
						Message.error(res.data.message)
					}else if(payload.err == 0){
                        MessageBox.alert(res.data.message, '提示信息')
					}
				}
			},1)
		},
		//修改/设置支付密码
		resetPayPassword({state,commit},payload){
			axiosApi("/anon/cbs/pay/setPayPwd",{payPwd:payload},res=>{
				if(res.data.data)commit('stepdeal')
			},null,null,res=>{
				 MessageBox.alert(res.data.message, '提示信息')
			})
		},
		//解绑银行卡
		unbindCard({state},payload){
			state.unresult = null//重置此字段，可监听到
			axiosApi("/anon/cbs/pay/unbindCard",{bankId:payload.bankcode,vcode:payload.vcode},res=>{
				state.unresult =  res.data.data
			},null,null,res=>{
				if(res.data.code.indexOf("SMS")>-1){
					state.errormsg = res.data.code=="SMS0006"?"输入错误次数过多，请稍后再试":res.data.message
				}else{
					Message(res.data.message)
				}
			})
		},
		// //1.20交易记录查询zjl
		// paymentQuery({state,commit}){
		// 	axiosApi('/anon/cbs/pay/paymentQuery',state.formInline,(res)=>{
		// 		({elements:state.paymentdata, totalCount:state.total} = res.data.data)
		// 		state.isTabShow = state.formInline.tradeTypeId == null||state.formInline.tradeTypeId == ""
		// 	},state.currentPage,state.pageSize,(res)=>{
				
		// 	},1)
		// },
		// 授信余额记录列表
		getCreditList({ state, commit }, payload) {
			state.loading = true
			let { type, page,pageSize, BeginDate, EndDate, buyerId } = payload
			let queryBeginDate = BeginDate?new Date(BeginDate).getTime():BeginDate
			let queryEndDate = EndDate?new Date(EndDate).getTime():EndDate
			axiosApi('/trader/buyer/financeAnalyse/get', { queryBeginDate, queryEndDate, buyerId }, (res) => {
				if (res.data.code === "000000") {
					var info = res.data.data

					state.amountInfo = {
						beginningBalance: info.beginningBalance,
						endingBalance: info.endingBalance,
						saleAnalysePeriodsAmount: info.saleAnalysePeriodsAmount,
						expenseAnalysePeriodsAmount: info.expenseAnalysePeriodsAmount,
						receiptAnalysePeriodsAmount: info.receiptAnalysePeriodsAmount
					}
					state.saleAnalyseList = info.saleAnalyseList;
					state.expenseAnalyseList = info.expenseAnalyseList;
					state.receiptAnalyseList = info.receiptAnalyseList;
					commit('setPaging', { type: type, page: page, pageSize })
				} else { 
					// 清空数据
					state.amountInfo = {
						beginningBalance: 0,
						endingBalance: 0,
						saleAnalysePeriodsAmount: 0,
						expenseAnalysePeriodsAmount: 0,
						receiptAnalysePeriodsAmount: 0
					}
					state.showCreditList = [];
					state.saleAnalyseList = [];
					state.expenseAnalyseList = [];
					state.receiptAnalyseList = [];
					state.showCreditTotal = 0
				}
				
				state.loading = false
			}, null, null, (res) => {
				// 清空数据
				state.amountInfo = {
					beginningBalance: 0,
					endingBalance: 0,
					saleAnalysePeriodsAmount: 0,
					expenseAnalysePeriodsAmount: 0,
					receiptAnalysePeriodsAmount: 0
				}
				state.showCreditList = [];
				state.saleAnalyseList = [];
				state.expenseAnalyseList = [];
				state.receiptAnalyseList = [];
				state.showCreditTotal = 0
				state.loading = false
			})
		},
		//提现申请
		applyCash({state},payload){
			axiosApi("/anon/cbs/pay/applyCash", {
                cashAmount: payload.cashAmount,
                cashBankId: payload.cashBankId,
                payPassword: payload.payPassword,
			}, res => {
				if(res.data.data.result){
					let payform = Object.assign(JSON.parse(sessionStorage.getItem("payform")), {
						gmtCash: res.data.data.gmtCash
					})
					sessionStorage.setItem("payform", JSON.stringify(payform))
				}
				m.$router.push(`${basePath}/pay/verifyresult/2/${res.data.data.result?1:0}`)
			},null,null,res=>{
                    state.errormsg = res.data.message
                    MessageBox.confirm(res.data.message, '提示', {
                    confirmButtonText: '确定',
                    showCancelButton:false,
                    center: true
                }).then(() => {

                })
            },1)
		}
	},
	getters: {
		selection(state){ //业务大小类级联
			let arr = state.tradeType.filter(function(item) {
	          return item.value == state.formInline.tradeTypeId;
	        })
			state.formInline.busiTypeId = null
			return arr.length>0?arr[0].children:[]
		},
		tradeType(state){
			return state.tradeType
		},
		formInline(state){
			return state.formInline
		},
		paymentdata(state){
			return state.paymentdata
		},
		showCreditList(state){
			return state.showCreditList
		},
		saleAnalyseList(state){
			return state.saleAnalyseList
		},
		expenseAnalyseList(state){
			return state.expenseAnalyseList
		},
		receiptAnalyseList(state){
			return state.receiptAnalyseList
		},
		
		showCreditTotal(state) { 
			return state.showCreditTotal
		},
		amountInfo(state) { 
			return state.amountInfo
		},
		loading(state) {
			return state.loading
		},
		total(state){
			return state.total
		},
		currentPage(state){
			return state.currentPage
		},
		fundsType(state){
			return state.fundsType
		},
		isTabShow(state){
			return state.isTabShow
		}
	}
}

export default paymodule